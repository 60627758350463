.thankYou {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__logo {
    position: absolute;
    top: 10px;
    left: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;

    &__skilliumLogo {
      display: none;
    }

    h2 {
      font-weight: 400;
      font-size: 64px;
      line-height: 42px;
      text-align: center;
      color: #000000;
      margin: 0;
    }

    p {
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 36px;
      line-height: 130%;
      text-align: center;
      color: #000000;
      max-width: 428px;
    }

    span {
      margin-top: 39px;
      margin-bottom: 34px;
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #000000;
      max-width: 579px;
    }
    &__btn {
      margin-top: 32px;
      width: 320px;
    }
  }

  &__notSlots {
    p {
      margin-bottom: 0;
    }
    span {
      margin-top: 39px;
      margin-bottom: 0px;
      max-width: 585px;
    }

    .thankYou__info__btn {
      margin-top: 25px;
    }
  }
}

@media (max-width: 450px) {
  .thankYou {
    &__info {
      h2 {
        font-size: 38px;
      }
      p {
        font-size: 24px;
      }
      span {
        margin-bottom: 32px;
        font-size: 16px;
        margin-bottom: 19px;
      }
    }

    &__notSlots {
      h2 {
        font-size: 38px;
      }
      p {
        margin-top: 10px;
        font-size: 24px;
      }
      span {
        font-size: 16px;
        margin-top: 20px;
        line-height: 24px;
        margin-bottom: 0px;
        max-width: 320px;
      }
    }
  }
}

// SKILLIUM DESIGN

:global(body.skilliumDesign) {
  .thankYou {
    display: block;
    * {
      font-family: Rubik;
    }
    &__logo {
      display: none;
    }

    &__info {
      margin-top: 10%;
      @media (max-width: 600px) {
        margin-top: 160px;
      }

      padding: 0 22px;
      &__skilliumLogo {
        display: block;
        width: 111px;
        margin-bottom: 73px;
      }
      h2 {
        color: #fff;
        font-size: 36.667px;
        font-style: normal;
        font-weight: 700;
        line-height: 38.5px; /* 105% */
        text-transform: uppercase;

        @media (min-width: 550px) {
          font-size: 40px;
        }
      }

      p {
        color: #d8d5d1;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 16.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 18.333px; /* 111.111% */
        letter-spacing: -0.458px;
        text-transform: uppercase;
        margin-top: 14px;
        margin-bottom: 30px;

        @media (min-width: 550px) {
          font-size: 20px;
        }
      }

      span {
        color: #f1ede9;
        text-align: center;
        font-family: Inter;
        font-size: 18.333px;
        font-style: normal;
        font-weight: 500;
        line-height: 23.833px; /* 130% */
        letter-spacing: -0.458px;
        max-width: none;

        &:nth-child(1) {
          margin-top: 53px;
        }

        @media (min-width: 550px) {
          font-size: 22px;
        }
      }

      &__btn {
        max-width: 320px;
        width: 100%;
        margin-top: 160px;

        button {
          box-sizing: border-box;
          width: 100%;
          font-family: Rubik;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px; /* 120% */
          letter-spacing: -0.8px;
          text-transform: uppercase;
          border: 4.5px solid var(--action-color);
          color: var(--action-color);
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: none;

          svg {
            display: none;
          }
        }
      }
    }
  }
}
