.reschedule {
  margin: 37px auto;
  width: 320px;

  &__head {
    &__title {
      margin-bottom: 19px;
      h1 {
        margin: 0;
        font-weight: 400;
        font-size: 30px;
        line-height: 44px;
        text-align: center;
        color: #171818;
        padding-bottom: 24px;
      }

      p img {
        margin-right: 7px;
        width: 12px;
        height: 13px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #0e081e;
        margin: 0;
      }
    }
  }

  &__confirm {
    opacity: 0.6;
  }

  &__messenger {
    height: 114px;
    display: flex;
    align-items: end;

    p {
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      margin-bottom: 11px;
    }
  }

  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    a {
      margin-top: 20px;
      font-weight: 400;
      font-size: 15.936px;
      line-height: 11px;
      text-align: center;
      text-decoration-line: underline;
      color: #0e081e;
    }
  }
}

@media (max-width: 540px) {
  .reschedule {
    margin-top: 13px;

    &__head {
      &__title {
        h1 {
          padding-bottom: 17px;
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .reschedule {
    width: 500px;
    &__head {
      &__title {
        h1 {
          font-size: 34px;
        }

        p {
          width: 500px;
          font-size: 20px;

          img {
            margin-right: 20px;
            width: 16px;
            height: 17px;
          }
        }
      }
    }

    &__messenger {
      p {
        font-size: 20px;
        margin-bottom: 17px;
      }
    }

    a {
      font-size: 24px;
    }

    &__btn {
      width: 500px;
      gap: 15px;
    }
  }
}

// SKILLIUM DESIGN

:global(body.skilliumDesign) {
  .reschedule {
    max-width: calc(100vw - 44px);
    width: 100%;
    padding: 0 22px;

    @media (min-width: 550px) {
      max-width: 500px;
    }

    &__head__title {
      margin-bottom: 44px;
      h1 {
        color: #fff;
        margin-bottom: 14px;
        padding: 0;
        font-family: Rubik;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 25.667px; /* 116.667% */
        text-transform: uppercase;
        @media (min-width: 550px) {
          font-size: 26px;
        }
      }

      p {
        color: #d8d5d1;
        margin: 0;
        font-size: 18.333px;
        font-style: normal;
        font-weight: 500;
        line-height: 23.833px; /* 130% */
        letter-spacing: -0.458px;
        width: auto;

        @media (min-width: 550px) {
          font-size: 20px;
        }

        img {
          display: none;
        }
      }
    }

    &__messenger {
      height: 156px;
      p {
        font-size: 20px;
        margin-bottom: 22px;
        color: #f1ede9;
        text-align: center;
      }
    }

    &__btn {
      width: 100%;

      svg {
        display: none;
      }
    }

    a {
      order: 1;
      text-decoration: none;
      text-transform: uppercase;
      width: 100%;
      font-family: Rubik;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
      letter-spacing: -0.8px;
      text-transform: uppercase;
      border: 4.5px solid var(--action-color);
      color: var(--action-color);
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 14px;
      box-sizing: border-box;
    }
  }
}
