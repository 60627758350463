.confirmedModal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  z-index: 200;
  box-sizing: border-box;
  padding: 25px 16px;
  overflow: auto;

  @media (max-width: 380px) {
    & {
      padding: 25px 10px;
    }
  }

  &__content {
    margin: auto;
    max-width: 475px;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;

    &__header {
      padding: 24px 16px 10px;
      background: #d3d7e2;
      color: #171818;
      font-family: 'Neue Montreal';
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 35.2px */

      @media (min-width: 540px) {
        font-size: 28px;
      }
    }

    &__alert {
      color: #171818;
      font-family: 'Neue Montreal';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 21.6px */
      padding: 12px;
      background: #f2be39;

      @media (min-width: 540px) {
        font-size: 16px;
      }
    }

    &__instructor {
      margin-top: -1px;
      background: #d3d7e2;
      padding: 10px 24px 0 5px;
      display: grid;
      gap: 8px;
      grid-template-columns: 1fr 0.65fr;

      @media (max-width: 390px) {
        & {
          grid-template-columns: 1fr 1fr;
        }
      }

      @media (min-width: 540px) {
        gap: 12px;
      }

      &__photo {
        display: flex;
        align-items: end;
        justify-content: center;

        img {
          width: auto;
          max-height: 216px;
          height: 100%;
          max-width: 100%;

          @media (min-width: 540px) {
            max-height: 250px;
          }
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 10px;

        h3 {
          color: #171818;
          font-family: 'Neue Montreal';
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          margin: 0;
        }

        p {
          color: #171818;
          font-family: 'Neue Montreal';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          margin: 0;
        }

        &__sign {
          margin-top: 8px;
          width: 80%;
          max-width: 110px;
        }

        @media (max-width: 390px) {
          & {
            h3 {
              br {
                display: none;
              }
            }

            &__sign {
              width: 40%;
            }
          }
        }

        @media (min-width: 540px) {
          h3 {
            font-size: 20px;
          }

          p {
            font-size: 16px;
          }
        }
      }
    }

    &__footer {
      padding: 20px 17px;
      background: #fff;
      display: flex;
      flex-direction: column;
      gap: 16px;

      p {
        color: #171818;
        font-family: 'Neue Montreal';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 21.6px */
        margin: 0;
      }

      button svg {
        margin-top: 2px;
      }

      &__downBlock {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          color: #212121;
          font-family: 'Neue Montreal';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22.588px; /* 161.345% */
        }

        &__rescheduleBlock {
          display: flex;
          align-items: center;
          gap: 4px;

          a {
            color: #212121;
            font-family: 'Neue Montreal';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 22.588px; /* 161.345% */
            text-decoration-line: underline;
          }
        }
      }

      @media (min-width: 540px) {
        p {
          font-size: 15px;
        }

        &__downBlock {
          span {
            font-size: 18px;
          }

          &__rescheduleBlock {
            svg {
              width: 28px;
              height: auto;
            }

            a {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
