@font-face {
  font-family: 'Nekst';
  src: local('Nekst'), url('./assets/fonts/Serebryakov\ -\ Nekst\ Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'Nekst';
  src: local('Nekst'), url('./assets/fonts/Serebryakov\ -\ Nekst\ Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Nekst';
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/about-us/fonts/Nekst-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Nekst';
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/about-us/fonts/Nekst-Medium.ttf')
    format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/fonts/Inter-Black.ttf')
    format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Inter';
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/fonts/Inter-ExtraBold.ttf')
    format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/fonts/Inter-Bold.ttf')
    format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/fonts/Inter-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/fonts/Inter-Medium.ttf')
    format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/fonts/Inter-Regular.ttf')
    format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Rubik';
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/fonts/Rubik-Black.ttf')
    format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'Rubik';
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/fonts/Rubik-Bold.ttf')
    format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Rubik';
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/fonts/Rubik-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Rubik';
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/fonts/Rubik-Medium.ttf')
    format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Rubik';
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/fonts/Rubik-Medium.ttf')
    format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: Neue Montreal;
  src: local('Neue Montreal'), url('./assets/fonts/NeueMontreal-Bold.ttf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Neue Montreal;
  src: local('Neue Montreal'), url('./assets/fonts/NeueMontreal-Medium.ttf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-display: swap;
  font-family: Neue Montreal;
  font-style: normal;
  font-weight: 400;
  src: url('https://7907058.fs1.hubspotusercontent-na1.net/hubfs/7907058/pages-files/fonts/NeueMontreal-Regular.ttf')
    format('truetype');
}

body {
  background: #f5f5f5;
  font-family: 'Nekst';
  margin: 0;
}

:global(body .skilliumIcon) {
  display: none;
}

:global(body.skilliumDesign .elvtrIcon) {
  display: none;
}

:global(body.skilliumDesign .skilliumIcon) {
  display: inline;
}

:global(body.skilliumDesign) {
  background: radial-gradient(72.84% 103.18% at 0% 7.44%, #4e5435 0%, #191919 100%);
  font-family: 'Inter';

  --action-color: #f0feae;
  --dark: #191919;
}
