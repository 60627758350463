.BookMeetingDropdownItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 30px;
  cursor: pointer;
  &:hover {
    background: rgb(183, 178, 178);
  }
  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #171818;
    margin: 0;
    font-family: 'Barlow';
    padding: 0 10px 0 20px;
    white-space: nowrap;
  }

  &__timezone {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
