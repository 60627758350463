.BookMeeting {
  margin: 37px auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 500px;

  h1 {
    font-weight: 400;
    font-size: 34px;
    line-height: 44px;
    color: #171818;
    margin: 0;
  }

  &__info {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #171818;
    margin: 15px 0 21px;
    font-family: 'Barlow', sans-serif;
  }

  &__alert {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    gap: 7px;
    margin: 14px 0 7px;

    p {
      font-weight: 700;
      font-size: 18px;
      line-height: 15px;
      text-align: center;
      margin: 0 0 0 10px;
      color: #0e081e;
    }
    span {
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      color: #171818;
    }
  }

  &__callMeNow {
    background: #F73A3A;
    border: none;
    color: white;
    cursor: pointer;
    display: block;
    font: bold 20px Nekst;
    margin-bottom: 21px;
    padding: 10px;
    width: 100%;

    &:hover {
      background: #F33636;
    }

    &[disabled] {
      background: #cb5f5f;
    }
  }
}

.reschedule {
  h1 {
    font-size: 33px;
  }
}

.isLoading {
  position: fixed;
  top: -9999px;
}

@media (max-width: 600px) {
  .BookMeeting {
    h1 {
      font-size: 29px;
    }

    p {
      font-size: 16px;
    }
  }
  .reschedule {
    h1 {
      font-size: 33px;
    }
  }
}

@media (max-width: 520px) {
  .reschedule {
    h1 {
      font-size: 31px;
    }
  }
}

@media (max-width: 490px) {
  .reschedule {
    h1 {
      font-size: 29px;
    }
  }
}

@media (max-width: 470px) {
  .BookMeeting {
    &__alert {
      span {
        font-size: 14px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .reschedule {
    h1 {
      font-size: 29px;
    }
  }
}

@media (max-width: 460px) {
  .reschedule {
    h1 {
      font-size: 28px;
    }
  }
}

@media (max-width: 445px) {
  .BookMeeting {
    &__alert {
      span {
        font-size: 13px;
      }
      p {
        font-size: 15px;
      }
    }
  }
  .reschedule {
    h1 {
      font-size: 26px;
    }
  }
}

@media (max-width: 415px) {
  .BookMeeting {
    &__alert {
      span {
        font-size: 12px;
      }
      p {
        font-size: 14px;
      }
    }
  }
  .reschedule {
    h1 {
      font-size: 24px;
    }
  }
}

@media (max-width: 390px) {
  .BookMeeting {
    h1 {
      font-size: 26px;
    }
    &__alert {
      span {
        font-size: 11px;
      }
      p {
        font-size: 13px;
      }
    }
  }
  .reschedule {
    h1 {
      font-size: 22px;
    }
  }
}

@media (max-width: 360px) {
  .BookMeeting {
    h1 {
      font-size: 23px;
    }
    &__alert {
      span {
        font-size: 10px;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .reschedule {
    h1 {
      font-size: 21px;
    }
  }
}

@media (max-width: 340px) {
  .reschedule {
    h1 {
      font-size: 20px;
    }
  }
}

@media (max-width: 330px) {
  .BookMeeting {
    &__alert {
      span {
        font-size: 9px;
      }
      p {
        font-size: 11px;
      }
    }
  }
  .reschedule {
    h1 {
      font-size: 19px;
    }
  }
}

.BookMeetingGlobal {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  &__container {
    margin: 0 auto;
    height: 100vh;
    overflow: auto;
    display: flex;
    min-width: 50%;
  }
  &__video {
    width: auto;
    padding-right: 90px;

    &__alert {
      display: none;
      max-width: 500px;
      margin: 0 auto;
      width: 100%;
    }
  }
}

@media (max-width: 1200px) {
  .BookMeetingGlobal {
    flex-direction: column;
    // max-height: 100vh;
    &__video {
      max-width: 500px;
      width: 100%;
      padding: 0;

      &__alert {
        display: block;
      }
    }

    &__container {
      flex-grow: 1;
      overflow: unset;
      width: 100%;
      display: block;
      height: auto;
    }
    .BookMeeting {
      .BookMeeting__alert {
        display: none;
      }
    }
    .reschedule {
      .BookMeeting__alert {
        display: block;
      }
    }
  }
}

// SKILLIUM DESIGN

:global(body.skilliumDesign) {
  .BookMeeting {
    position: relative;
    margin-top: 0;
    padding-top: 87px;
    padding-left: 22px;
    padding-right: 22px;

    h1 {
      color: #fff;
      font-family: Rubik;
      font-style: normal;
      font-weight: 700;
      line-height: 25.667px; /* 116.667% */
    }

    &__alert {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      margin: 0;
      height: auto;
      padding: 7.3px 22px;
      box-sizing: border-box;
      background: #e5f744;
    }

    &__info {
      font-family: 'Inter';
      color: #f1ede9;

      font-style: normal;
      font-weight: 500;
      line-height: 23.833px; /* 130% */
      letter-spacing: -0.458px;
      margin-bottom: 23px;
      @media (max-width: 600px) {
        font-size: 18.333px;
      }
    }
  }
}
