.bookMeetingAlert {
  font-family: 'Barlow';
  width: 100%;
  margin-top: -3px;
  height: 33px;
  background: #ffcf72;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;

  &__slilliumTheme {
    display: none;
  }

  &__elvtrTheme {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
  }

  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 15px;
    text-align: center;
    color: #0e081e;
  }
  span {
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    color: #171818;
  }
}

@media (max-width: 420px) {
  .bookMeetingAlert {
    height: 30px;
    transform: translateY(-1px);

    p {
      font-size: 19px;
    }

    span {
      font-size: 16px;
    }
  }
}

@media (max-width: 400px) {
  .bookMeetingAlert {
    p {
      font-size: 17px;
    }

    span {
      font-size: 14px;
    }
  }
}

@media (max-width: 380px) {
  .bookMeetingAlert {
    height: 24px;

    p {
      font-size: 14px;
    }

    span {
      font-size: 12px;
    }
  }
}

// SKILLIUM DESIGN

:global(body.skilliumDesign) {
  .bookMeetingAlert {
    background: none;
    justify-content: left;
    font-family: 'Inter';
    height: auto;
    * {
      font-weight: 500;
    }
    &__elvtrTheme {
      display: none;
    }
    &__slilliumTheme {
      display: block;
      text-align: start;

      span {
        line-height: normal;
        @media (max-width: 500px) {
          font-size: 15px;
        }

        @media (max-width: 440px) {
          font-size: 13px;
        }

        @media (max-width: 390px) {
          font-size: 12.3px;
        }

        @media (max-width: 370px) {
          font-size: 11px;
        }

        @media (max-width: 340px) {
          font-size: 10.5px;
        }
      }

      span span {
        text-decoration: underline;
      }
    }
  }
}
