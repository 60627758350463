.BookMeetingDateList {
  display: flex;
  justify-content: space-between;
}

// SKILLIUM DESIGN

:global(body.skilliumDesign) {
  .BookMeetingDateList {
    gap: 7.3px;
    overflow: auto;
    margin-right: -22px;
    padding-right: 22px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
