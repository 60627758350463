.BookMeetingTime {
  width: 30%;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  text-decoration: none;

  h3 {
    font-weight: 700;
    font-size: 15px;
    line-height: 32px;
    color: #0e081e;
    margin: 0;
  }

  &:disabled {
    cursor: default;
    position: relative;
    z-index: 10040;

    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 2.824px 0px rgba(0, 0, 0, 0.25);
  }
}

@media (max-width: 500px) {
  .BookMeetingTime {
    height: 40px;

    h3 {
      font-size: 13px;
    }
  }
}

:global(body.skilliumDesign) {
  .BookMeetingTime {
    border: 2.75px solid var(--dark);
    background: #fff;
    transition: all 0.3s;
    margin-bottom: 15px;

    &:hover {
      background: var(--action-color);
    }
  }

  h3 {
    font-size: 12.833px;
    font-style: normal;
    font-weight: 600;
    line-height: 23.833px; /* 185.714% */
    letter-spacing: -0.458px;

    @media (min-width: 600px) {
      font-size: 16px;
    }
  }
}
