.BookMeetingTimeList {
  &__Block {
    display: flex;
    gap: 5%;
    margin-top: 28px;
    flex-wrap: wrap;

    &__backdrop {
      position: absolute;
      width: 100%;
      height: calc(100% + 72px);
      min-height: 100vh;
      background: #f7f9fa;
      top: -37px;
      left: 0;
      opacity: 0.75;
      z-index: 10020;
    }
  }
  &__Button {
    margin-top: 3px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #0e081e;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    text-decoration: underline;

    &__Active {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .BookMeetingTimeList {
    &__Button {
      font-size: 12px;
      margin-bottom: 60px;
    }
  }
}

:global(body.skilliumDesign) {
  .BookMeetingTimeList {
    margin: 0 -22px;
    padding: 15px 22px;
    background: #f1ede9;
    gap: 4.2%;
    display: flex;
    flex-direction: column;

    &__Block {
      margin-top: 0px;
    }

    &__Button {
      margin: 0;
      text-decoration: none;
      font-family: Rubik;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
      letter-spacing: -0.8px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 14px;
      justify-content: center;

      svg {
        display: inline;
      }

      &__Active {
        display: none;
      }
    }
  }
}
