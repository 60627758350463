.BookMeetingDate {
  width: 15%;
  height: 130px;
  background: #ffffff;
  cursor: pointer;
  &__Header {
    width: 100%;
    height: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9e99ab;

    h3 {
      font-weight: 700;
      font-size: 12px;
      margin: 0;
    }
  }

  &__Number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 78%;
    background: #ffffff;
    h2 {
      font-weight: 700;
      font-size: 30px;
      margin: 0;
    }
    h4 {
      font-weight: 700;
      font-size: 15px;
      margin: -5px 0 0;
    }
  }

  &__Active {
    .BookMeetingDate__Header {
      background: #140046;

      h3 {
        color: #ffffff;
      }
    }
    .BookMeetingDate__Number {
      background: #443b5c;
      color: #ffffff;
    }
  }

  &__Disabled {
    cursor: default;
    .BookMeetingDate__Number {
      color: #9e99ab;
    }
  }
}

@media (max-width: 500px) {
  .BookMeetingDate {
    height: 100px;

    &__Header {
      font-size: 10px;
    }
    &__Number {
      h2 {
        font-size: 18px;
      }
      h4 {
        font-size: 10px;
      }
    }
  }
}

@media (max-width: 420px) {
  .BookMeetingDate {
    height: 80px;
  }
}

// SKILLIUM DESIGN

:global(body.skilliumDesign) {
  .BookMeetingDate {
    border: 2.75px solid var(--dark);
    height: 101px;
    min-width: 68.75px;

    @media (min-width: 600px) {
      height: 130px;
    }

    &__Header {
      height: 40%;
      background: var(--dark);
      h3 {
        font-size: 16.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 18.333px; /* 111.111% */
        letter-spacing: -0.458px;
        text-transform: uppercase;
        color: #fff;

        @media (min-width: 600px) {
          font-size: 19px;
        }
      }
    }

    &__Number {
      height: 60%;

      h2 {
        font-family: Rubik;
        font-weight: 700;
        line-height: 25.667px; /* 116.667% */
        font-size: 22px;

        @media (min-width: 600px) {
          font-size: 25px;
        }
      }

      h4 {
        font-size: 16.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 18.333px; /* 111.111% */
        letter-spacing: -0.458px;
        text-transform: uppercase;
        margin-top: 3px;

        @media (min-width: 600px) {
          font-size: 20px;
        }
      }
    }

    &__Active {
      .BookMeetingDate__Header {
        h3 {
          color: var(--action-color);
        }
      }
      .BookMeetingDate__Number {
        background: var(--action-color);
        color: var(--dark);
      }
    }

    &__Disabled {
      opacity: 0.1;
    }
  }
}
