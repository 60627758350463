@keyframes btn-loader {
  0% {
    opacity: 1;
  }

  100% {
    transform: 0.5;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;

  span {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background: #010101;
    opacity: 0.5;
    animation: btn-loader 1.2s infinite;
  }

  span:nth-child(2) {
    animation-delay: 0.4s;
  }

  span:nth-child(3) {
    animation-delay: 0.8s;
  }
}
