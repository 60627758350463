.gradient {
  background-color: rgb(212, 212, 212);
}

.centralMap {
  margin: 37px auto;
  max-width: 500px;
  padding: 0 15px;
}

.headSkeleton {
  width: 100%;
  height: 44px;
  position: relative;
  overflow: hidden;
}

.headSkeleton::before {
  content: '';
  display: block;
  position: absolute;
  left: -900px;
  top: 0;
  height: 100%;
  width: 900px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 3s cubic-bezier(0.4, 0, 0.2, 1);
}

.warningSkeleton {
  width: 100%;
  height: 35px;
  margin-top: 14px;
  position: relative;
  overflow: hidden;
}

.warningSkeleton::before {
  content: '';
  display: block;
  position: absolute;
  left: -900px;
  top: 0;
  height: 100%;
  width: 900px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 3s cubic-bezier(0.4, 0, 0.2, 1);
}

.textSkeleton {
  width: 100%;
  height: 89px;
  margin-top: 22px;
  position: relative;
  overflow: hidden;
}

.textSkeleton::before {
  content: '';
  display: block;
  position: absolute;
  left: -900px;
  top: 0;
  height: 100%;
  width: 900px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 3s cubic-bezier(0.4, 0, 0.2, 1);
}

.cardBox {
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
}

.cardSkeleton {
  height: 130px;
  width: 15%;
  position: relative;
  overflow: hidden;
}

.cardSkeleton::before {
  content: '';
  display: block;
  position: absolute;
  left: -900px;
  top: 0;
  height: 100%;
  width: 900px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 3s cubic-bezier(0.4, 0, 0.2, 1);
}

.standardTimeSkeleton {
  width: 167px;
  height: 19px;
  margin-top: 28px;
  position: relative;
  overflow: hidden;
}

.standardTimeSkeleton::before {
  content: '';
  display: block;
  position: absolute;
  left: -900px;
  top: 0;
  height: 100%;
  width: 900px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 3s cubic-bezier(0.4, 0, 0.2, 1);
}

.timeCardSkeleton {
  margin-top: 28px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.timingSkeleton {
  width: 30%;
  height: 50px;
  margin-bottom: 19px;
  position: relative;
  overflow: hidden;
}

.timingSkeleton::before {
  content: '';
  display: block;
  position: absolute;
  left: -900px;
  top: 0;
  height: 100%;
  width: 900px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 3s cubic-bezier(0.4, 0, 0.2, 1);
}

.moreSlotsSkeleton {
  width: 133px;
  height: 19px;
  margin-top: 3px;
  position: relative;
  overflow: hidden;
}

.moreSlotsSkeleton::before {
  content: '';
  display: block;
  position: absolute;
  left: -900px;
  top: 0;
  height: 100%;
  width: 900px;
  background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
  animation: load 3s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes load {
  from {
    left: -900px;
  }
  to {
    left: 100%;
  }
}

@media (max-width: 500px) {
  .cardSkeleton {
    height: 100px;
  }
}

@media (max-width: 420px) {
  .cardSkeleton {
    height: 80px;
  }
}

@media (max-width: 500px) {
  .timingSkeleton {
    height: 40px;
  }
}

// SKILLIUM DESIGN

:global(body.skilliumDesign) {
  .centralMap {
    margin: 0 auto;
    position: relative;

    padding: 0 22px;

    .headSkeleton {
      margin: -87px -22px 0;
      position: absolute;
      height: 51px;

      @media (max-width: 450px) {
        height: 55px;
      }
    }

    .warningSkeleton {
      margin-top: 87px;
      @media (max-width: 450px) {
        height: 60px;
      }
    }

    .textSkeleton {
      margin-top: 10px;
      height: 75px;

      @media (max-width: 450px) {
        height: 101px;
      }
    }

    .cardBox {
      margin-top: 22px;
      gap: 7.3px;
      overflow: auto;
      margin-right: -22px;
      padding-right: 22px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .cardSkeleton {
      min-width: 68px;
      height: 130px;

      @media (max-width: 600px) {
        height: 101px;
      }
    }

    .standardTimeSkeleton {
      margin-top: 52px;
      width: 227px;
    }

    .timeCardSkeleton {
      margin-top: 19px;
    }

    .timingSkeleton {
      margin-bottom: 15px;
    }

    .moreSlotsSkeleton {
      position: relative;
      width: 100%;

      &::after {
        position: absolute;
        top: 0;
        left: -50px;
        transform: translateX(-50%);
        width: 100px;
        height: 100%;
        background: red;
      }
    }
  }
}
