.contacts {
  width: 317px;
  background: #ffffff;
  box-shadow: 0px 0px 4.04211px rgba(0, 0, 0, 0.25);

  &__slilliumTheme {
    display: none !important;
  }

  &__info {
    padding: 20px 0 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__item {
      display: flex;
      align-items: center;
      gap: 14px;
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 400;
      font-size: 16.1684px;
      color: #0e081e;
    }
  }
}

@media (min-width: 600px) {
  .contacts {
    width: 100%;

    &__info {
      &__item {
        gap: 18px;
      }

      p {
        font-size: 20px;
      }
    }
  }
}

// SKILLIUM DESIGN

:global(body.skilliumDesign) {
  .contacts {
    width: 100%;
    border: 2.75px solid var(--dark);
    box-sizing: border-box;
    background: #fff;

    &__slilliumTheme {
      display: flex !important;

      &__header {
        padding: 7px 22px;
        background: var(--dark);

        p {
          margin: 0;
          color: var(--action-color);
          font-family: Inter;
          font-size: 16.5px;
          font-style: normal;
          font-weight: 600;
          line-height: 18.333px; /* 111.111% */
          letter-spacing: -0.458px;
          text-transform: uppercase;
        }
      }
    }

    &__elvtrTheme {
      display: none;
    }

    &__info {
      padding: 22px;
      gap: 14px;

      &__item {
        p {
          font-family: Inter;
          font-size: 16.5px;
          font-style: normal;
          font-weight: 600;
          line-height: 18.333px; /* 111.111% */
          letter-spacing: -0.458px;
        }

        &__date {
          text-transform: uppercase;
        }
      }
    }
  }
}
