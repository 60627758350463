.InterviewConfirmed {
  margin: 37px auto;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__Text {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-weight: 400;
      font-size: 27px;
      line-height: 44px;
      color: #171818;
      margin: 0;
      text-align: center;
    }

    p {
      margin: 24px 0 19px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #0e081e;
      width: 285px;
      font-family: 'Barlow', sans-serif;

      img {
        margin: 0 9px 0 5px;
      }
    }
  }
  img {
    margin-bottom: 15px;
    width: 12px;
    height: 13px;
  }

  a {
    margin-top: 24px;
    font-family: 'Barlow';
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    text-align: center;
    text-decoration-line: underline;
    color: #0e081e;
  }

  &__btn {
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
  }

  &__alert {
    margin-top: 25px;
    margin-bottom: 44px;
    background: rgba(255, 224, 168, 0.7);
    border-radius: 5px;
    padding: 13px 16px 17px 21px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 12px;
    text-align: center;

    span {
      font-weight: 700;
      font-size: 16px;
    }

    p {
      margin: 0;
      font-weight: 400;
      line-height: 19px;
    }
  }
}

@media (max-width: 540px) {
  .InterviewConfirmed {
    margin-top: 13px;

    &__Text {
      p {
        margin-top: 17px;
      }
    }
  }
}

@media (min-width: 600px) {
  .InterviewConfirmed {
    width: 500px;

    &__Text {
      h1 {
        font-size: 34px;
      }

      p {
        width: 500px;
        font-size: 20px;
      }
    }

    img {
      margin-right: 20px;
      width: 16px;
      height: 17px;
    }

    a {
      font-size: 20px;
    }

    &__btn {
      width: 500px;
    }
  }
}

// SKILLIUM DESIGN

:global(body.skilliumDesign) {
  .InterviewConfirmed {
    max-width: calc(100vw - 44px);
    width: 100%;
    padding: 0 22px;

    @media (min-width: 550px) {
      max-width: 500px;
    }

    &__Text {
      gap: 14px;
      margin-bottom: 44px;
      h1 {
        color: #fff;
        font-family: Rubik;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 25.667px; /* 116.667% */
        text-transform: uppercase;
        @media (min-width: 550px) {
          font-size: 26px;
        }
      }

      p {
        color: #d8d5d1;
        margin: 0;
        font-size: 18.333px;
        font-style: normal;
        font-weight: 500;
        line-height: 23.833px; /* 130% */
        letter-spacing: -0.458px;
        width: auto;

        @media (min-width: 550px) {
          font-size: 20px;
        }

        img {
          display: none;
        }
      }
    }

    &__btn {
      width: 100%;

      svg {
        display: none;
      }
    }

    a {
      order: 1;
      text-decoration: none;
      text-transform: uppercase;
      width: 100%;
      font-family: Rubik;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
      letter-spacing: -0.8px;
      text-transform: uppercase;
      border: 4.5px solid var(--action-color);
      color: var(--action-color);
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 14px;
      box-sizing: border-box;
    }
  }
}
