.BookMeetingDropdown {
  position: relative;
  margin-top: 26px;

  button {
    background: none;
    padding: 0;
    border: none;
    font-family: 'Barlow';
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    color: #0e081e;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__list {
    position: absolute;
    background: #f5f5f5;
    width: 101%;
    left: -3px;
    height: 100%;

    h2 {
      font-family: 'Barlow';
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #0e081e;
      margin: 0;
      padding: 5px 10px;
    }
  }
}

.BookMeetingDropdown {
  @media (min-width: 500px) {
    &__list {
      height: 409px;
      z-index: 9999;
    }
  }
}

.active {
  @media (max-width: 500px) {
    margin-top: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    padding: 26px 10px 0 10px;
    box-sizing: border-box;
    background: #f5f5f5;
    overflow: auto;
  }
}

:global(body.skilliumDesign) {
  .BookMeetingDropdown {
    padding-top: 12px;
    background: #f1ede9;
    margin: 36px -22px 0;
    padding: 12px 22px 0;

    button {
      font-family: Inter;
      color: var(--dark);
      font-size: 12.833px;
      font-style: normal;
      font-weight: 500;
      line-height: 23.833px;
      letter-spacing: -0.458px;
    }

    &__list {
      width: 100%;
      left: 22px;
      background: #f1ede9;

      @media (min-width: 500px) {
        left: 0;
      }
    }

    &.active {
      @media (max-width: 500px) {
        margin-top: 0;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        width: 100vh;
        height: 100%;
        z-index: 9999;
        padding: 26px 10px 0 10px;
        box-sizing: border-box;
        background: #f1ede9;
      }
    }
  }
}
