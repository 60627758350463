.interviewConfirmedInfo {
  width: 100%;
  font-family: 'Barlow';
  &__alert {
    margin-top: 25px;
    margin-bottom: 44px;
    background: rgba(255, 224, 168, 0.7);
    border-radius: 5px;
    padding: 13px 16px 17px 21px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 12px;
    text-align: center;

    span {
      margin: 0;
      font-weight: 700;
      font-size: 16px;
    }

    p {
      margin: 0;
      font-weight: 400;
      line-height: 19px;
    }
  }

  &__block {
    height: 75px;
  }

  &__text {
    font-weight: 400;
    padding: 45px 0 20px;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
}

@media (min-width: 600px) {
  .interviewConfirmedInfo {
    &__alert {
      padding: 22px 37px 25px;
      p {
        font-size: 18px;
        line-height: 25px;
      }

      span {
        font-size: 20px;
      }
    }

    &__text {
      font-size: 20px;
      line-height: 28px;
      padding: 50px 0 25px;
    }
  }
}

// SKILLIUM DESIGN

:global(body.skilliumDesign) {
  .interviewConfirmedInfo {
    margin: 36px 0 48px;

    &__alert {
      padding: 8px 22px;
      background: #f1ede9;
      margin: 0;
      border-radius: 0px;
      text-align: left;
      gap: 0;
      color: var(--dark);
      font-family: Inter;
      font-size: 12.833px;
      font-style: normal;
      font-weight: 500;
      line-height: 23.833px;
      letter-spacing: -0.458px;

      @media (min-width: 600px) {
        font-size: 16px;
      }
    }

    &__text {
      color: #f1ede9;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px; /* 130% */
      letter-spacing: -0.5px;
    }
  }
}
